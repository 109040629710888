var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    settings: true,
    'common-page': true,
    'common-page-blurred': _vm.bgPrepared && _vm.settings.useBlur,
  }},[_c('div',{staticClass:"settings-list"},[_c('button',{ref:"addBtn",class:{
        'add-to-home-screen': true,
        'icon-button': true,
        'add-to-home-screen-blurred': _vm.bgPrepared,
      }},[_c('span',{staticClass:"iconfont icon-add"}),_c('span',{staticClass:"tip"},[_vm._v("添加到桌面")])]),_vm._m(0),_c('div',{staticClass:"settings-sublist"},[_c('div',{staticClass:"settings-item"},[_c('div',{class:{
            'settings-item-basic': true,
            'ef-fadein': true,
          },on:{"click":function($event){return _vm.detailChangeLegacy()}}},[_c('div',{staticClass:"left"},[_c('span',{class:'settings-icon iconfont icon-cog'}),_vm._m(1)]),_c('div',{staticClass:"right"},[_c('button',{staticClass:"icon-button"},[_c('span',{class:{
                  iconfont: true,
                  'icon-chevron-down': !_vm.detailShow,
                  'icon-chevron-up': _vm.detailShow,
                },on:{"~click":function($event){return _vm.detailChangeLegacy()}}})])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.detailShow)?_c('div',{staticClass:"settings-item-more"},[_c('div',{staticClass:"settings-sub-item"},[_c('button',{staticClass:"icon-button",on:{"click":_vm.resetMainPageData}},[_vm._v(" 重置 ")])]),_c('div',{staticClass:"settings-sub-item settings-drag"},[_c('transition-group',{staticClass:"drag-list",attrs:{"name":"drag","tag":"ul"}},_vm._l((_vm.mainPageData),function(item,index){return _c('li',{key:item.component,class:{ 'drag-item': true, 'drag-item-hide': item.hide },attrs:{"draggable":!item.hide},on:{"dragenter":function($event){return _vm.dragenter($event, index)},"dragover":function($event){return _vm.dragover($event, index)},"dragstart":function($event){return _vm.dragstart(index)}}},[_c('span',{staticClass:"card-title"},[_vm._v(_vm._s(item.title))]),_c('button',{staticClass:"card-func icon-button",on:{"click":function($event){item.hide ? _vm.showCard(index) : _vm.hideCard(index)}}},[_c('span',{class:{
                        iconfont: true,
                        'icon-circleminus': !item.hide,
                        'icon-add': item.hide,
                      }})])])}),0)],1)]):_vm._e()])],1)])]),_vm._l((_vm.settingSchema),function(item,index){return _c('div',{key:index,staticClass:"settings-list"},[_c('div',{staticClass:"sub-header"},[_c('span',{staticClass:"title",domProps:{"textContent":_vm._s(item.title)}})]),_c('div',{staticClass:"settings-sublist"},_vm._l((item.children),function(item_1,index_1){return (_vm.itemExists(item_1, 'ifShow') ? _vm.settings[item_1.ifShow] : true)?_c('div',{key:index_1,class:{
          'settings-item': true,
        }},[_c('div',{class:{
            'settings-item-basic': true,
            'ef-fadein': item_1.inputType == 'more',
          },on:{"click":function($event){item_1.inputType == 'more' ? _vm.detailChange(item_1) : ''}}},[_c('div',{staticClass:"left"},[_c('span',{class:'settings-icon iconfont ' +
                (_vm.itemExists(item_1, 'icon') ? item_1.icon : 'icon-cog')}),_c('div',{staticClass:"info"},[_c('span',{staticClass:"title",domProps:{"textContent":_vm._s(item_1.title)}}),(item_1.desc)?_c('span',{staticClass:"desc",domProps:{"textContent":_vm._s(item_1.desc)}}):_vm._e()])]),_c('div',{staticClass:"right"},[((item_1.inputType)==='checkbox'&&(
                item_1.inputType == 'checkbox' || item_1.inputType == 'text'
              ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings[item_1.model]),expression:"settings[item_1.model]"}],staticClass:"better-input",attrs:{"name":item_1.model,"id":item_1.model,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.settings[item_1.model])?_vm._i(_vm.settings[item_1.model],null)>-1:(_vm.settings[item_1.model])},on:{"click":function($event){return _vm.forceUpdateSettings()},"keyup":function($event){return _vm.forceUpdateSettings()},"mouseout":function($event){return _vm.forceUpdateSettings()},"mouseleave":function($event){return _vm.forceUpdateSettings()},"change":function($event){var $$a=_vm.settings[item_1.model],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.settings, item_1.model, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.settings, item_1.model, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.settings, item_1.model, $$c)}}}}):((item_1.inputType)==='radio'&&(
                item_1.inputType == 'checkbox' || item_1.inputType == 'text'
              ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings[item_1.model]),expression:"settings[item_1.model]"}],staticClass:"better-input",attrs:{"name":item_1.model,"id":item_1.model,"type":"radio"},domProps:{"checked":_vm._q(_vm.settings[item_1.model],null)},on:{"click":function($event){return _vm.forceUpdateSettings()},"keyup":function($event){return _vm.forceUpdateSettings()},"mouseout":function($event){return _vm.forceUpdateSettings()},"mouseleave":function($event){return _vm.forceUpdateSettings()},"change":function($event){return _vm.$set(_vm.settings, item_1.model, null)}}}):(
                item_1.inputType == 'checkbox' || item_1.inputType == 'text'
              )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings[item_1.model]),expression:"settings[item_1.model]"}],staticClass:"better-input",attrs:{"name":item_1.model,"id":item_1.model,"type":item_1.inputType},domProps:{"value":(_vm.settings[item_1.model])},on:{"click":function($event){return _vm.forceUpdateSettings()},"keyup":function($event){return _vm.forceUpdateSettings()},"mouseout":function($event){return _vm.forceUpdateSettings()},"mouseleave":function($event){return _vm.forceUpdateSettings()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, item_1.model, $event.target.value)}}}):_vm._e(),(item_1.inputType == 'checkbox')?_c('label',{attrs:{"for":item_1.model}}):_vm._e(),(item_1.inputType == 'more')?_c('div',{staticClass:"type-more"},[_c('button',{staticClass:"icon-button"},[_c('span',{class:{
                    iconfont: true,
                    'icon-chevron-down': !item_1.detailShow,
                    'icon-chevron-up': item_1.detailShow,
                  },on:{"~click":function($event){return _vm.detailChange(item_1)}}})])]):_vm._e(),(item_1.inputType == 'button')?_c('div',{staticClass:"type-button"},[_c('button',{staticClass:"common-button",domProps:{"textContent":_vm._s(item_1.buttonTitle)},on:{"click":function($event){return _vm.execFunc(item_1)}}})]):_vm._e()])]),_c('transition',{attrs:{"name":"fade"}},[(item_1.detailShow)?_c('div',{staticClass:"settings-item-more"},_vm._l((item_1.details),function(item_2,index_2){return _c('div',{key:index_2,staticClass:"settings-sub-item"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"info"},[_c('span',{staticClass:"title",domProps:{"textContent":_vm._s(item_2.title)}})])]),_c('div',{staticClass:"right"},[((item_2.inputType)==='checkbox'&&(
                    item_2.inputType == 'checkbox' ||
                    item_2.inputType == 'text'
                  ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings[item_1.model][item_2.model]),expression:"settings[item_1.model][item_2.model]"}],staticClass:"better-input",attrs:{"name":item_2.model,"id":item_2.model,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.settings[item_1.model][item_2.model])?_vm._i(_vm.settings[item_1.model][item_2.model],null)>-1:(_vm.settings[item_1.model][item_2.model])},on:{"click":function($event){return _vm.forceUpdateSettings()},"keyup":function($event){return _vm.forceUpdateSettings()},"mouseout":function($event){return _vm.forceUpdateSettings()},"mouseleave":function($event){return _vm.forceUpdateSettings()},"change":function($event){var $$a=_vm.settings[item_1.model][item_2.model],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.settings[item_1.model], item_2.model, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.settings[item_1.model], item_2.model, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.settings[item_1.model], item_2.model, $$c)}}}}):((item_2.inputType)==='radio'&&(
                    item_2.inputType == 'checkbox' ||
                    item_2.inputType == 'text'
                  ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings[item_1.model][item_2.model]),expression:"settings[item_1.model][item_2.model]"}],staticClass:"better-input",attrs:{"name":item_2.model,"id":item_2.model,"type":"radio"},domProps:{"checked":_vm._q(_vm.settings[item_1.model][item_2.model],null)},on:{"click":function($event){return _vm.forceUpdateSettings()},"keyup":function($event){return _vm.forceUpdateSettings()},"mouseout":function($event){return _vm.forceUpdateSettings()},"mouseleave":function($event){return _vm.forceUpdateSettings()},"change":function($event){return _vm.$set(_vm.settings[item_1.model], item_2.model, null)}}}):(
                    item_2.inputType == 'checkbox' ||
                    item_2.inputType == 'text'
                  )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.settings[item_1.model][item_2.model]),expression:"settings[item_1.model][item_2.model]"}],staticClass:"better-input",attrs:{"name":item_2.model,"id":item_2.model,"type":item_2.inputType},domProps:{"value":(_vm.settings[item_1.model][item_2.model])},on:{"click":function($event){return _vm.forceUpdateSettings()},"keyup":function($event){return _vm.forceUpdateSettings()},"mouseout":function($event){return _vm.forceUpdateSettings()},"mouseleave":function($event){return _vm.forceUpdateSettings()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings[item_1.model], item_2.model, $event.target.value)}}}):_vm._e(),(item_2.inputType == 'checkbox')?_c('label',{attrs:{"for":item_2.model}}):_vm._e(),(item_1.inputType == 'button')?_c('div',{staticClass:"type-button"},[_c('button',{staticClass:"common-button",domProps:{"textContent":_vm._s(item_2.buttonTitle)},on:{"click":function($event){return _vm.execFunc(item_2)}}})]):_vm._e()])])}),0):_vm._e()])],1):_vm._e()}),0)])}),_vm._m(2)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-header"},[_c('span',{staticClass:"title"},[_vm._v("卡片")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info"},[_c('span',{staticClass:"title"},[_vm._v("卡片管理")]),_c('span',{staticClass:"desc"},[_vm._v("对首页卡片进行排序或隐藏")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about"},[_c('span',{staticClass:"about-line",staticStyle:{"text-align":"center"}},[_vm._v(" Built with 🧡 by Mercutio ")]),_c('span',{staticClass:"about-line",staticStyle:{"text-align":"center"}},[_c('a',{staticClass:"link-button",attrs:{"href":"https://www.mercutio.club","target":"_blank","title":"Mercutio的博客"}},[_vm._v(" MercBlog ")])]),_c('span',{staticClass:"about-line"},[_vm._v("来源：")]),_c('ul',{staticClass:"about-list"},[_c('li',{staticClass:"about-line"},[_vm._v("壁纸资源来自Unsplash")]),_c('li',{staticClass:"about-line"},[_vm._v("音乐内容来自网易云音乐")]),_c('li',{staticClass:"about-line"},[_vm._v("视频内容来自Bilibili")]),_c('li',{staticClass:"about-line"},[_vm._v("天气数据来自和风天气")]),_c('li',{staticClass:"about-line"},[_vm._v("一言数据来自Hitokoto")])]),_c('span',{staticClass:"about-line"},[_vm._v("免责声明：")]),_c('ul',{staticClass:"about-list"},[_c('li',{staticClass:"about-line"},[_vm._v(" 壁纸内容由Unsplash实时自动随机选择，本网站不做人工筛选，亦不对壁纸内容负责。 ")]),_c('li',{staticClass:"about-line"},[_vm._v(" 一言内容由Hitokoto实时自动随机选择，本网站不做人工筛选，亦不对语句内容负责。 ")])]),_c('span',{staticClass:"about-line"},[_vm._v("Copyright © 2021 Mercutio")])])}]

export { render, staticRenderFns }